import { Inject, Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UtilService } from './util.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(@Inject(Router) private router: Router,
    @Inject(UtilService) private utilService: UtilService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const errorMessage = 'Você não está autorizado à acessar esta funcionalidade, por favor, efetue login e tente novamente';

    if (this.utilService.isLogado()) {
      if (route.data && route.data.role) {
        const roles = this.utilService.getDadosLogado().roles as Array<string>;
        const podeAcessar = route.data.role.some((r: string) => roles.includes(r));
        if (!podeAcessar) {
          this.utilService.errorMsg(errorMessage, () => {
            this.utilService.backToCaller(this.router, ['app/checklist/aplicacao']);
          });
        }
        return podeAcessar;
      }
      return true;
    }

    this.utilService.errorMsg(errorMessage, () => {
      this.router.navigate(['auth/login']);
    });

    return true;
  }
}
