import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Router } from '@angular/router';
import { RouteStack } from '../model/route-stack';
import { LOCALSTORAGE_TOKEN_KEY } from './constantes';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UsuarioJwt } from '../model/usuario-jwt';
import { MatSnackBar, MatSnackBarDismiss } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';

const jwtService = new JwtHelperService();
declare var swal: any;

@Injectable()
export class UtilService {

  constructor(private snackBar: MatSnackBar) {
  }

  private routeStack = [] as RouteStack[];

  static attrWithoutReference(objeto): any {
    return Object.assign({}, objeto);
  }

  static confirmarSenha(senhaControl: AbstractControl): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const valid = senhaControl.value === control.value;
      return valid ? null : { confirmarSenha: true };
    };
  }

  static confirmarEmail(emailControl: AbstractControl): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const valid = emailControl.value === control.value;
      return valid ? null : { confirmarEmail: true };
    };
  }

  static validateArray(control: AbstractControl): ValidationErrors | null {
    const valid = control.value && control.value.length > 0;
    return valid ? null : { validateArray: true };
  }

  static cnpj(control: AbstractControl): ValidationErrors | null {
    const valid = UtilService.validateCNPJ(control.value);
    return valid ? null : { cnpj: true };
  }

  static cpf(control: AbstractControl): ValidationErrors | null {
    const valid = UtilService.validateCPF(control.value);
    return valid ? null : { cpf: true };
  }

  static placa(control: AbstractControl): ValidationErrors | null {
    const match = control.value.match(/^[a-zA-Z]{3}\-\d{4}$/);
    return match ? null : { placa: true };
  }

  static validateDate(control: AbstractControl): ValidationErrors | null {
    // tslint:disable-next-line:max-line-length
    const match = control.value.match(/^(^(((0[1-9]|1[0-9]|2[0-8])[\/](0[1-9]|1[012]))|((29|30|31)[\/](0[13578]|1[02]))|((29|30)[\/](0[4,6,9]|11)))[\/](19|[2-9][0-9])\d\d$)|(^29[\/]02[\/](19|[2-9][0-9])(00|04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96)$)/);
    return match ? null : { validateDate: true };
  }

  public showErrors(form: any): void {
    if (!form) {
      throw new Error('[showErrors] O FormGroup não deve estar nulo!');
    }
    Object.keys(form.controls).forEach(key => {
      const field = form.get(key);
      if (field['controls']) {
        this.showErrors(field);
      } else {
        field.markAsTouched();
      }
    });
  }

  public errorMsg(msg: string = 'Ocorreu um erro desconhecido! Favor, entre em contato com nossos administradores',
    callback: Function = () => {
    }): void {
    swal({
      title: 'Erro',
      text: msg,
      icon: 'error',
      button: 'Entendi',
    }).then(callback);
  }

  public errorMsgPersonalizada(msg: string, callback: Function = () => {
    }): void {
    swal({
      title: 'Erro',
      text: msg,
      icon: 'error',
      button: 'Entendi',
    }).then(callback);
  }

  public successMsg(msg: string, callback: Function = () => {
  }): void {
    this.validateMsg(msg);
    swal({
      title: 'Sucesso!',
      text: msg,
      icon: 'success',
      button: 'Feito',
    }).then(callback);
  }

  public successDynamic(msg: string): Observable<MatSnackBarDismiss> {
    this.validateMsg(msg);
    const sb = this.snackBar.open(msg, null, {
      duration: 3000
    });
    return sb.afterDismissed();
  }

  public successMsgInternal(msg: string): void {
    this.validateMsg(msg);
    swal({
      title: 'Sucesso!',
      text: msg,
      icon: 'success',
      button: 'Feito',
    });
  }

  public informationMsg(msg: string): void {
    this.validateMsg(msg);
    swal({
      title: 'Informação!',
      text: msg,
      icon: 'info',
      button: 'Ok',
    });
  }

  public confirmMsg(msg: string, title: string, callback: Function): void {
    this.validateMsg(msg);
    this.validarCallback(callback);
    swal({
      title: title,
      text: msg,
      icon: 'warning',
      buttons: {
        cancel: {
          text: 'Não',
          value: false,
          visible: true,
          closeModal: true,
        },
        confirm: {
          text: 'Sim',
          value: true,
          visible: true,
          closeModal: true
        }
      }
    }).then(callback);
  }

  public alertMsg(msg: string, callback: Function): void {
    this.validateMsg(msg);
    this.validarCallback(callback);
    swal({
      title: 'Alerta!',
      text: msg,
      icon: 'warning',
      buttons: 'OK',
    }).then(callback);
  }

  private validateMsg(mensagem: string): void {
    if (!mensagem) {
      throw new Error('É obrigatório definir uma mensagem');
    }
  }

  private validarCallback(callback: Function): void {
    if (!callback) {
      throw new Error('É obrigatório definir um callback');
    }
  }

  public saveCookies(data: string): void {
    localStorage.setItem(LOCALSTORAGE_TOKEN_KEY, data);
  }

  public clearCookies(): void {
    localStorage.clear();
  }

  public isLogado(): boolean {
    const item = localStorage.getItem(LOCALSTORAGE_TOKEN_KEY);
    return !!item;
  }

  public getToken(): string {
    if (this.isLogado()) {
      const jwt = localStorage.getItem(LOCALSTORAGE_TOKEN_KEY);
      return jwt;
    }
    return null;
  }

  public getDadosLogado(): UsuarioJwt {
    if (this.isLogado()) {
      const jwt = localStorage.getItem(LOCALSTORAGE_TOKEN_KEY);
      const decoded = jwtService.decodeToken(jwt) as UsuarioJwt;
      return decoded;
    }
    return null;
  }

  public getNome(): string {
    if (this.isLogado()) {
      const jwt = localStorage.getItem(LOCALSTORAGE_TOKEN_KEY);
      const decoded = jwtService.decodeToken(jwt) as UsuarioJwt;
      return decoded.nome;
    }
    return null;
  }

  public getRoles(): string[] {
    if (this.isLogado()) {
      const jwt = localStorage.getItem(LOCALSTORAGE_TOKEN_KEY);
      const decoded = jwtService.decodeToken(jwt) as UsuarioJwt;
      return decoded.roles;
    }
    return null;
  }

  public getIdLogado(): number {
    if (this.isLogado()) {
      const jwt = localStorage.getItem(LOCALSTORAGE_TOKEN_KEY);
      const decoded = jwtService.decodeToken(jwt) as UsuarioJwt;
      return decoded.id;
    }
    return null;
  }

  public goTo(router: Router, route: string, ...params: any[]): void {
    if (params && params.length) {
      router.navigate(this.separateArrays(route, params));
    } else {
      router.navigate([route]);
    }
    this.routeStack.push({
      route: route,
      caller: router.url,
      params: params ? params : []
    } as RouteStack);
  }

  private separateArrays(route: string, params): string[] {
    const array = [];

    array.push(route);

    if (typeof params === 'string') {
      array.push(params);
      return array;
    }

    for (let i = 0; i < params.length; i++) {
      array.push(params[i]);
    }

    return array;
  }

  public backToCaller(router: Router, defaultBackRoute: any[]): void {
    if (!this.isRouteStackEmpty()) {
      const unstacked = this.routeStack.pop();
      router.navigate([decodeURI(unstacked.caller)]);
    } else {
      router.navigate(defaultBackRoute);
    }
  }

  public clearRouteStack(): void {
    this.routeStack = [];
  }

  public isRouteStackEmpty(): boolean {
    return !this.routeStack || !this.routeStack.length;
  }

  public getDayOfWeek(dateString: string): string {
    const days = ['Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado'];
    const arr = dateString.split('/').reverse();
    const date = new Date(Number(arr[0]), Number(arr[1]) - 1, Number(arr[2]));
    const dia = date.getDay();
    return days[dia];
  }

  public formatarCNPJ(cnpj: string): string {
    if (cnpj.length <= 14) {
      cnpj = cnpj.replace(/^(\d{2})(\d)/, '$1.$2');
      cnpj = cnpj.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2/$3');
      cnpj = cnpj.replace(/\.(\d{3})(\d)/, '.$1/$2');
      cnpj = cnpj.replace(/(\d{4})(\d)/, '$1-$2');
    }
    return cnpj;
  }

  public getPrimeiraUltimaPalavra(texto: string): string {
    if (!texto) { return ''; }
    const palavras = texto.split(' ');
    if (palavras.length === 1) { return texto; }
    return palavras[0] + ' ' + palavras.pop();
  }

  public getBase64(file: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      if (!file) {
        resolve('');
      } else {
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      }
    });
  }

  public getFirstDayOfMonth(): string {
    var date = new Date();
    var pDay = new Date(date.getFullYear(), date.getMonth(), 1);
    return pDay.toISOString().split("T")[0];
  }

  public getLastDayOfMonth(): string {
    var date = new Date();
    var uDday = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    return uDday.toISOString().split("T")[0];
  }

  public generateColors(size: number): string[] {
    const colors = [];
    for (let i = 0; i < size; i++) {
      var color = "rgba(" + Math.floor(Math.random() * 255) + "," + Math.floor(Math.random() * 255) + ","
        + Math.floor(Math.random() * 255) + ", 0.8)";
      colors.push(color);
    }
    return colors;
  }

  public isMobile() {
    if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) 
        || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4))) { 
        return true;
    }
    return false;
  }

  // tslint:disable-next-line:member-ordering
  static validateCNPJ(cnpj: any): boolean {
    cnpj = cnpj.replace(/[^\d]+/g, '');
    if (cnpj === '') {
      return false;
    }
    if (cnpj.length !== 14) {
      return false;
    }

    if (cnpj === '00000000000000' ||
      cnpj === '11111111111111' ||
      cnpj === '22222222222222' ||
      cnpj === '33333333333333' ||
      cnpj === '44444444444444' ||
      cnpj === '55555555555555' ||
      cnpj === '66666666666666' ||
      cnpj === '77777777777777' ||
      cnpj === '88888888888888' ||
      cnpj === '99999999999999') {
      return false;
    }

    // Validate DVs
    let size = cnpj.length - 2;
    let numbers = cnpj.substring(0, size);
    const digits = cnpj.substring(size);
    let sum = 0;
    let pos = size - 7;
    for (let i = size; i >= 1; i--) {
      sum += numbers.charAt(size - i) * pos--;
      if (pos < 2) {
        pos = 9;
      }
    }
    let result = sum % 11 < 2 ? 0 : 11 - sum % 11;
    // tslint:disable-next-line:triple-equals
    if (result != digits.charAt(0)) {
      return false;
    }
    size = size + 1;
    numbers = cnpj.substring(0, size);
    sum = 0;
    pos = size - 7;
    for (let i = size; i >= 1; i--) {
      sum += numbers.charAt(size - i) * pos--;
      if (pos < 2) {
        pos = 9;
      }
    }
    result = sum % 11 < 2 ? 0 : 11 - sum % 11;
    // tslint:disable-next-line:triple-equals
    return result == digits.charAt(1);
  }

  // tslint:disable-next-line:member-ordering
  static validateCPF(cpf: any): boolean {
    cpf = cpf.replace(/[^\d]+/g, '');
    if (cpf === '') {
      return false;
    }
    if (cpf.length !== 11) {
      return false;
    }
    let sum;
    let rest;
    sum = 0;
    // tslint:disable-next-line:triple-equals
    if (cpf == '00000000000') {
      return false;
    }

    for (let i = 1; i <= 9; i++) {
      // tslint:disable-next-line:radix
      sum = sum + parseInt(cpf.substring(i - 1, i), 10) * (11 - i);
    }
    rest = (sum * 10) % 11;

    // tslint:disable-next-line:triple-equals
    if ((rest == 10) || (rest == 11)) {
      rest = 0;
    }
    // tslint:disable-next-line:radix triple-equals
    if (rest != parseInt(cpf.substring(9, 10), 10)) {
      return false;
    }

    sum = 0;
    // tslint:disable-next-line:radix
    for (let i = 1; i <= 10; i++) {
      sum = sum + parseInt(cpf.substring(i - 1, i), 10) * (12 - i);
    }
    rest = (sum * 10) % 11;

    // tslint:disable-next-line:triple-equals
    if ((rest == 10) || (rest == 11)) {
      rest = 0;
    }
    // tslint:disable-next-line:radix triple-equals
    return rest == parseInt(cpf.substring(10, 11));
  }

}

